<template>
  <b-row>
    <b-col cols="12">
      <b-row class="match-height">
        <b-col>
          <b-card :class="utils.getColorDotProfile(features.aqs.data.mark)">
            <h3 class="text-white">{{$t(`tiktok.aqs_title`)}}</h3>
            <h1 class="text-white number-giant-tiktok">{{features.aqs.data.value}}</h1>
            <span class="text-muted color-span-giant">{{$t(`tiktok.of_100`)}}</span>
            <h3 class="text-white">{{$t(`tiktok.${features.aqs.data.mark}`)}}</h3>
          </b-card>
        </b-col>

        <b-col>
          <b-card>
            <div class="d-flex flex-column justify-content-between h-100">
              <h3 class="mb-2 d-block">{{$t('campaigns.followers')}}</h3>
              <div>
                <div><span class="h1">{{subs}}</span>
                  <span 
                    :class="`${subscribers_growth_prc.value > 0 ? 'text-success' : 'text-danger'}`"
                  > 
                    {{subscribers_growth_prc.value > 0 ? '+' : ''}}
                    {{subscribers_growth_prc.value.toFixed(1)}}%
                  </span>
                </div>
                <p>{{$t('tiktok.for_30_days')}}</p>
                <p class="d-flex align-items-center">
                  <span 
                    :class="`dot-profile-tiktok ${utils.getColorDotProfile(subscribers_growth_prc.mark)}`"
                  /> 
                  {{$t(`tiktok.${subscribers_growth_prc.mark}`)}}
                </p>
              </div>
            </div>
          </b-card>
        </b-col>


        <b-col>
          <b-card>
            <div class="d-flex flex-column justify-content-between h-100">
              <h3 class="mb-2">{{$t('profile.postFrequency')}}</h3>
              <div>
                <p><span class="h1">{{Math.round(post_frequency.value)}}</span> {{$t('tiktok.for_30_days')}}</p>
                  <p class="d-flex align-items-center">
                    <span 
                      :class="`dot-profile-tiktok ${utils.getColorDotProfile(post_frequency.mark)}`"
                    /> 
                    {{$t(`tiktok.${post_frequency.mark}`)}}
                  </p>
              </div>
            </div>
          </b-card>
        </b-col>
        
        <b-col>
          <b-card>
            <div class="d-flex flex-column justify-content-between h-100">
              <h3 class="mb-2">{{$t('tiktok.average_views')}}</h3>
              <div>
                <div><span class="h1">{{views_avg}}</span></div>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col>
          <b-card>
            <div class="d-flex flex-column justify-content-between h-100">
              <h3 class="mb-2">{{$t('twitch.categories')}}</h3>
              <div v-if="categories.length > 1">
                <b-badge 
                  v-for="(badge, index) in categories" 
                  :key="index" 
                  variant="light-secondary" 
                  class="mr-1"
                > 
                  <span v-if="utils.checkNumber(badge)">{{getCategory(badge)}}</span>
                  <span v-else>{{badge}}</span>
                </b-badge>
              </div>
              <div class="h1" v-else>
                N/A
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="d-flex align-items-stretch">
          <b-card :class="`${getColorv2(features.aqs.data.mark)} col-12`">
            <div>
              <h3 class="text-white">Pros y contras</h3>
              <p 
                class="text-white" 
                v-for="(i, index) in features.aqs.data.description" 
                :key="index"
              >
                <span v-if="i !== null" class="d-flex align-items-center">
                  <span 
                    :class="`d-block dot-profile-tiktok ${utils.getColorDotProfile(i.mark)}`"
                  /> 
                  <span class="d-block">{{$t(`tiktok.${i.description.title.key}`)}}</span>
                  <!-- {{$t(`tiktok.${i.description.title.text}`)}} -->
                </span>
              </p>
            </div>
          </b-card>
        </b-col>

        <b-col class="d-flex align-items-stretch">
          <b-card class="col-12">
            <div class="d-flex flex-column justify-content-between h-100">
              <h3 class="mb-2">{{$t('twitter.engagementRate')}}</h3>
              <div>
                <div><span class="h1">{{er.value.toFixed(2)}}%</span></div>
                <p class="d-flex align-items-center">
                  <span :class="`dot-profile-tiktok ${utils.getColorDotProfile(er.mark_title.toLowerCase())}`" />
                  {{$t(`tiktok.${er.mark_title.toLowerCase()}`)}} 
                </p>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col class="d-flex align-items-stretch">
          <b-card class="col-12">
            <div class="d-flex flex-column justify-content-between h-100">
              <h3 class="mb-2">{{$t('tiktok.comments_likes_rat')}}</h3>
              <div>
                <div>
                  <span class="h1">{{comments_likes_ratio.value.toFixed(2)}}</span> <span >{{$t(`tiktok.comments_100`)}}</span></div>
                  <p class="d-flex align-items-center">
                    <span :class="`dot-profile-tiktok ${utils.getColorDotProfile(comments_likes_ratio.mark_title.toLowerCase())}`" />
                    {{$t(`tiktok.${comments_likes_ratio.mark_title.toLowerCase()}`)}} 
                  </p>              
                </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BRow, BCol, BBadge
} from 'bootstrap-vue'
import utils from '@/libs/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
  },
  props: {
    metrics: {
      type: Object,
      required: true
    },
    features: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      utils
    }
  },
  created() {
  },
  computed: {
    views_avg() {
      return utils.getFormat(this.metrics.views_avg.value);
    },
    post_frequency() {
      return this.metrics.post_frequency.performance['30d'];
    },
    subscribers_growth_prc() {
      return this.metrics.subscribers_growth_prc.performance['30d'];
    },
    comments_likes_ratio() {
      return this.metrics.comments_likes_ratio;
    },
    subs() {
      return utils.getFormat(this.metrics.subscribers_count.value)
    },
    er() {
      return this.metrics.er;
    },
    categories() {
      return this.features.blogger_thematics.data
    }
  },
  methods: {

    getColorv2(mark) {
      const obj = {
        poor: 'dark-danger',
        fair: 'dark-danger',
        average: 'dark-warning',
        good: 'dark-success',
        very_good: 'dark-success',
        excellent: 'dark-success',
      }
      return obj[mark]
    },
    getCategory(category) {
      const exist_category = this.$t('tiktokCategory').find((e) => { return e.value === category })
      if (exist_category) return exist_category.text
    } 
  }
}
</script>
<style>
.number-giant-tiktok {
  font-size: 70px !important;
  margin: 0;
}
.color-span-giant {
  display: block;
  color: white !important;
  margin-bottom: 1em;
}
.dot-profile-tiktok {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  margin: 0 0.3em 0 0;
}
.dark-success {
  background-color: #103D21;
}
.dark-danger {
  background-color: #572611;
}
.dark-warning {
  background-color: #553C0A;
}
</style>